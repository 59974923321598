@import '../../../styles/colors.scss';

.products-line {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  &__name {
    width: 45%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
  }

  &__input {
    width: 35%;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__unit {
      width: 5%;
      font-weight: bold;
    }
  }

  &__icon {
    width: 15%;
    border: none;
    background-color: $transparent;

    &:focus {
      outline: none;
    }
    &__img {
      width: 20px;
      height: 20px;
    }
  }
}

.products-line-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px 0 35px;
  &__name {
    width: 50%;
    margin-bottom: 0;
    font-weight: 600;
  }
  &__values {
    display: flex;
    flex-direction: row;
    width: 25%;

    &__quantity {
      font-style: italic;
      width: 95%;
      margin-bottom: 0;
      text-align: right;
      position: relative;
      left: -50px;
    }

    &__unit {
      width: 5%;
      font-weight: bold;
      width: 5%;
      margin-bottom: 0;
      margin-left: 15px;
      position: relative;
      left: -50px;
    }
  }
}
