@import '../../styles/colors.scss';

.following-delivery {
  background-color: $white-nav;
  overflow: hidden;
  height: 40%;
  background-color: $white;

  &__mini {
    position: absolute;
    top: 75px;
    left: 88px;
    width: 95%;
    background-color: $white-nav;
    &__container {
      padding: 1% 1% 0;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      height: 90vh;

      &__left {
        width: 50%;
        background-color: $white;
        border-radius: 5px;
        border: 1px solid rgba(68, 66, 67, 0.2);

        &__body {
          overflow-y: scroll;
          overflow-x: hidden;
          max-height: 89%;

          &__line {
            border: none;
            background-color: $transparent;
            width: 100%;
            padding: 0;

            &:focus {
              outline: none;
            }

            &:nth-child(2n) {
              background-color: $white-nav;
            }
          }
        }
      }

      &__right {
        width: 50%;
        background-color: $blue-selec;
        border-radius: 0 5px 5px 0;
        border: 1px solid rgba(5, 131, 242, 0.2);

        &__overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }

        &__top {
          height: 20%;
          width: 95%;
          margin: 1% auto;
          background-color: $white;
          border-radius: 5px;
          border: 1px solid rgba(68, 66, 67, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          &__horodatage {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 5px 5px 0;
            &__text1 {
              color: $blue;
              font-size: 12px;
              font-weight: 600;
              margin-bottom: 0;
            }
            &__text2 {
              color: $blue;
              font-size: 12px;
              margin-left: 10px;
            }
          }

          &__input {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 90%;
            margin: auto;
            &__icon {
              border: none;
              background-color: $transparent;

              &:focus {
                outline: none;
              }
              &__img {
                width: 22px;
              }
            }
          }
        }

        &__middle {
          height: 65%;
          width: 95%;
          margin: 1% auto;
          background-color: $white;
          border-radius: 5px;
          border: 1px solid rgba(68, 66, 67, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          &__input {
            width: 90%;
            margin: auto;
          }

          &__cliNim {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            margin: auto;
          }

          &__products {
            width: 90%;
            margin: auto;
            display: flex;
            flex-direction: row;

            &__tittle {
              width: 5%;
              font-size: 20px;
              writing-mode: vertical-rl;
              text-orientation: mixed;
              transform: rotate(180deg);
              font-weight: bold;
            }

            &__grid {
              height: 100px;
              width: 95%;
              overflow-y: scroll;
              border: 1px solid rgba(68, 66, 67, 0.2);
              border-radius: 5px;

              &::-webkit-scrollbar-thumb {
                background: rgba(68, 66, 67, 0.8);
              }

              &__informations {
                &__line {
                  &:nth-child(2n) {
                    background-color: $white-nav;
                  }
                }
              }
            }
          }
        }

        &__bottom {
          height: 5%;
          width: 95%;
          margin: 1% auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &__button {
            width: 30%;
          }
        }
      }
    }
  }

  &__container {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 90%;
    max-height: 80%;
    min-height: 80%;
    margin: 0 auto 4%;
    background-color: $white;
    border-radius: 10px 10px 0px 0px;

    &::-webkit-scrollbar-thumb {
      background: rgba(68, 66, 67, 0.8);
    }

    &__more {
      width: 100%;
      text-align: center;

      &__button {
        background-color: $transparent;
        border: none;
        &:focus {
          outline: none;
        }

        &__text {
          font-weight: 700;
          font-size: 16px;
        }
      }
    }

    &__body {
      border: 1px solid $white-nav;

      &__line {
        border: none;
        background-color: $transparent;
        width: 100%;
        padding: 0;

        &:focus {
          outline: none;
        }

        &:nth-child(2n) {
          background-color: $white-nav;
        }
      }
    }
  }
}

.following-delivery__date-choice {
  position: relative;

  &__btn {
    margin-bottom: 0;
    width: 100%;
    height: 40px;
    border: 2px solid #0583f2;
    border-radius: 5px;
    background: white;
    box-shadow: 0 1px 3px -2px #9098a9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
    color: #0583f2;
    font-weight: 600;

    p {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &__date-picker {
    position: absolute;
    z-index: 1;
    border: 1px solid rgba(68, 66, 67, 0.2);
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.following-delivery__export {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__btn-xls {
    margin-left: 5px;
    height: 40px;
    width: 40px;
    background-color: rgba($color: $green-valid, $alpha: 0.1);
    border: 2px solid $green-valid;

    &--disabled {
      background-color: rgba($color: $gray-bdn, $alpha: 0.1);
      border: 2px solid $gray-bdn;
    }

    &:focus {
      outline: none;
    }

    &__img {
      width: 25px;
    }
  }

  &__btn {
    margin-bottom: 0;
    width: 100%;
    height: 40px;
    border: 2px solid #0583f2;
    border-radius: 5px;
    background: white;
    box-shadow: 0 1px 3px -2px #9098a9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
    color: #0583f2;
    font-weight: 600;

    p {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &__date-picker {
    position: absolute;
    z-index: 1;
    border: 1px solid rgba(68, 66, 67, 0.2);
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.following-delivery__header {
  height: 80px;
  width: 90%;
  margin: 4% auto 0;
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;

  &__search-open {
    // width: 80%;
  }

  &__dropdown {
    width: 20%;
  }

  &__dropdown1 {
    width: 13%;
  }

  &__search-bar {
    width: 20%;
    margin-right: 10px;
  }

  &__search-bar-mini {
    padding-right: 25px;

    &__open {
      // position: absolute;
      // right: 50%;
      // top: 21px;
      width: 20%;
    }
    &__button {
      background-color: $transparent;
      border: none;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      width: 35px;
      height: 35px;

      &:focus {
        outline: none;
      }

      &__icon {
        &__img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.loader {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  &:focus {
    outline: none;
    color: black;
  }

  &__text {
    font-size: 16px;
    font-weight: 700;
    color: black;
  }
}
