@import "../../styles/colors.scss";

.errorPopIn {
  position: fixed;
  left: calc(50% - 200px);
  width: 450px;
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgba($color: $red-error, $alpha: 1);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  background-color: #ffe6e5;
  z-index: 100;
  animation-name: BottomToTop;
  animation-duration: 0.2s;
  animation-fill-mode:forwards;

  &__title {
    display: flex;
    justify-content: center;
    color: $red-error;
  }

  &__code {
    display: flex;
    justify-content: center;
    color: $red-error;
  }

  &__text {
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    color: $red-error;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
}

@keyframes BottomToTop {
  0% {
    top: 150%;
  }
  100% {
    top: 35%;
  }
}