@import '../../../styles/colors.scss';

.materialsDelivery {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  background: $white;
  border: 1px solid rgba(68, 66, 67, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 5px;

  &__inputs {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__field {
      width: 30%;
    }
  }

  &__button {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__icon {
      border: none;
      background-color: $transparent;

      &:focus {
        outline: none;
      }
      &__img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
