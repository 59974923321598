@import "../../styles/colors.scss";

.login {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &__container {
    width: 60%;
    height: 70%;
    margin: 5% 20%;
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    &__layout {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3% 25% 10%;
      &__logo-bdn {
        width: 250px;
        margin: 0 auto;
      }

      &__submit {
       text-align: right;
       margin-right: 5%;

       &__link {
         position: relative;
         top: 10%;
         font-size: 16px;
         color: $gray-bdn;
         text-decoration: underline;
       }
      }

      &__error {
        color: $red-error;
        font-size: 14px;
        text-align: center;

      }
      &__no-error {
        color: $transparent;
      }
    }
  }
}