@import "../../styles/colors.scss";

.delivery {

  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  &__container {
    background-color: $white;
    width: 95%;
    height: 90%;
    margin: 3% auto;
    border: 1px solid rgba(68, 66, 67, 0.2);
    border-radius: 5px;
    overflow: hidden;

    &-resume {
      border: none;
    }

    // ========== Second step ==========

    &__second-step {
      padding: 1%;

      &__separator {
        height: 2px;
        width: 40%;
        background-color: rgba(68, 66, 67, 0.2);
        margin-bottom: 2%;
      }

      &__adding {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

        &__icon {
          border: none;
          background-color: $white;
          width: 50px;
          height: 50px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          &:focus {
            outline: none;
          }
          &__img {
            width: 20px;
          }
        }
      }

      &__materials {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 30vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
      }

      &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 50px;
        margin-top: 1%;
        
        &__register {
          margin-left: 5%
        }
      }

    }

    // ========== Resume step ==========

    &__resume-step {
      height: 100%;
      background-color: $white-nav;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__left {
        padding: 1% 1%;
        width: 49%;
        height: 100%;
        background-color: $white;
        border: 1px solid rgba(68, 66, 67, 0.2);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &__container-input {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
  
          &__num {
            margin-bottom: 0;
            font-size: 12px;
          }
        }
        &__container-BDC {
          display: flex;
          flex-direction: row;
          width: 100%;
          &__num {
            margin-bottom: 0;
            font-size: 12px;
            width: 5%;
          }
          &__prefix {
            font-weight: 600;
            width: 45%;
          }
          &__text {
            color: $blue;
            text-decoration: underline;
            font-weight: bold;
            font-size: 16px;
            width: 40%;
          }
          &__icon {
            width: 10%;
            &__img {
              width: 16px;
            }
          }
        }
      }

      &__right {
        width: 49%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__top {
          background-color: $white;
          height: 49%;
          border: 1px solid rgba(68, 66, 67, 0.2);
          border-radius: 5px;
          padding: 3% 3% 0;

          &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &__button {
              border: none;
              background-color: $transparent;

              &:focus {
                outline: none;
              }
              &__text {
                font-style: italic;
              }
            }
          }

          &__scroll {
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 80%;
            border: 1px solid rgba(68, 66, 67, 0.2);
            border-radius: 5px;
            &__line {
              &:nth-child(2n) {
                background-color: $white-nav;
                border: 1px solid rgba(68, 66, 67, 0.2);
              }
            }
          }
        }

        &__middle {
          background-color: $white;
          height: 35%;
          border: 1px solid rgba(68, 66, 67, 0.2);
          border-radius: 5px;
        }

        &__bottom {
          height: 50px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &__button {
            width: 33%;
          }
        }
      }
    }
  }
}
