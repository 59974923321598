@import '../../../styles/colors.scss';

.uploadBtn {
  margin-bottom: 0;
  width: 150px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 1px 3px -2px #9098a9;
  font-family: inherit;
  transition: all 150ms ease;
  font-weight: 600;
  margin-bottom: 5px;
  cursor: pointer;
  &__primary {
    background-color: $white;
    color: $blue;
    border: 1px solid $blue;
    &:hover {
      opacity: 0.8;
    }
  }
  &__text {
    width: 100%;
    margin: 0px;
    font-size: 13px;
    text-transform: uppercase;
  }
  &__formatText {
    margin: 0;
    font-weight: 300;
    font-size: 10px;
    opacity: 0.6;
    font-style: italic;
    text-align: center;
  }
}
