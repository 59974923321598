@import '../../../styles/colors.scss';

.tablet-user-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  width: 100%;

  &__selected {
    background-color: rgba(5, 131, 242, 0.2);
  }

  &__tablets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    &__left {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 75%;

      &__waiting {
        margin-bottom: 0;
        margin-right: 10px;
        //width: 90%;
        margin-left: 35px;
      }

      &__tablet {
        text-decoration: underline;
        font-weight: 600;
        width: 50%;
      }
    }

    &__right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 60%;
      &__site {
        font-style: italic;
        margin-bottom: 0;
        width: 50%;
        text-align: right;
        padding-right: 5%;
      }

      &__icon {
        width: 10%;
        border: none;
        background-color: $transparent;

        &:focus {
          outline: none;
        }
        &__img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &__users {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    width: 100%;
    &__selec {
      padding-left: 5%;
      width: 10%;
      &__circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $black;
        box-sizing: border-box;

        &__selected {
          background-color: $blue;
          border: none;
        }
      }
    }
    &__full-name {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 20%;
      &__firstname {
        margin-bottom: 0;
        font-weight: 600;
        margin-right: 5px;
      }
      &__name {
        margin-bottom: 0;
        font-weight: 600;
      }
    }
    &__job {
      width: 25%;
      margin-bottom: 0;
    }
    &__site {
      width: 35%;
      margin-bottom: 0;
      font-style: italic;
    }
    &__icon {
      width: 5%;
      background-color: $transparent;
      border: none;

      &:focus {
        outline: none;
      }
      &__img {
        width: 15px;
        height: 15px;
      }
    }
  }
}
