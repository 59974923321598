@import "../../styles/colors.scss";

.nav-bar {
  width: 220px;
  height: calc(100vh - 75px);
  border: 1px solid rgba(68, 66, 67, 0.2);
  padding: 0 0 7% 0;
  background-color: $white;

  &__deconection {
    &__version {
      &__text {
        font-size: 10px;
        padding-top: 10px;
        text-align: center;
      }
    }
    &__overlay {
      display: flex;
      flex-direction: column;
    }
  }

  &__logout {
    display: flex;
    background-color: transparent;
    border: none;
    align-items: center;
    &__img {
      width: 20px;
      margin-right: 10px;
      background-color: $transparent;
      border: none;
      &:focus {
        outline: none;
      }
    }

    &__text {
      color: $red-bdn;
      text-decoration: underline;
      font-weight: bold;
      margin-top: 5px;
    }
  }

  &__user-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 55px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    border: none;
    background-color: $white;
    
    &:focus {
      outline: none;
  }

    &__text {
      color: $gray-bdn;
      text-decoration: underline;
      font-weight: bold;
    }

    &__overlay {
      position: absolute;
      top: 130px;
      left: 0;
      z-index: 2;
      width: 220px;
      height: 55px;
      background-color: $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      border-right: 1px solid rgba(68, 66, 67, 0.2);
      &__icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background-color: $transparent;
        border: none;
        &:focus {
          outline: none;
        }
        &__img {
          width: 100%;
        }
      }

      &__text {
        color: $red-bdn;
        text-decoration: underline;
        font-weight: bold;
        margin-top: 5px;
      }
    }
  }

  &__container {
    width: 80%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a:hover {
      text-decoration: none;
    }

    &__third {
      position: relative;
      left: -12%;
      padding-left: 12%;
      background-color: $white-nav;
      border-radius: 0px 5px 5px 0px;
    }

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;
      width: 100%;

      &__mini {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      
      &__icon {
        width: 20px;
        height: auto;
        margin-right: 5px;

        &__delivery {
          width: 35px;
        }

        &__img {
          width: 100%;
        }
      }

      &__text {
        margin-left: 5%;
        color: $gray-bdn;
        margin-bottom: 0;
        &::first-letter {
          font-weight: bold;
          text-decoration: underline;
        }
      }

      &__notification {
        background: $blue-notification;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 6px;
        margin-left: 10%;

        &__active {
          background-color: $white;
        }
        
        &__number {
          color: $blue;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }

    &__item-active {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;
      background-color: rgba(5, 131, 242, 0.2);
      border-left: 10px solid rgb(5, 131, 242);
      position: relative;
      left: -13%;
      padding-left: 8%;
      width: 126%;
      border-radius: 0px 0px 10px 0px;

      &__third {
        left: -15%;
        width: 115%;
      }

      &__icon {
        width: 20px;
        height: auto;
        margin-right: 5px;

        &__img {
          width: 100%;
        }
      }

      &__text {
        margin-left: 5%;
        color: $blue;
        margin-bottom: 0;
        &::first-letter {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
}

 // ========= Mini Nav Bar =========

.nav-bar-mini {
  width: 88px;
  &__user-name {
    &__overlay {
      width: 88px;
    }
  }
}