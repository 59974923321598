@import '../../../styles/colors.scss';

.tablet-user-grid {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(68, 66, 67, 0.2);
  border-radius: 5px;
  &__button {
    background-color: $transparent;
    border: none;
    padding: 0;
    width: 100%;

    &:focus {
      outline: none;
    }
    &:nth-child(2n) {
      background-color: $white-nav;
    }
  }
}
