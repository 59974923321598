@import '../../../styles/colors.scss';

.trucks-grid {
  width: 100%;
  height: 100%;
  &__truck {
    border: none;
    background-color: $transparent;
    width: 100%;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:nth-child(2n) {
      background-color: $white-nav;
    }
  }
}
