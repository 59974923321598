@import "../../styles/colors.scss";

.tablets {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &__left {
      width: 65%;
      height: 100%;
      margin-top: 2%;
      margin-left: 2%;
      background-color: $white;
      border: 1px solid rgba(68, 66, 67, 0.2);
      box-sizing: border-box;
      border-radius: 10px 10px 0px 0px;
      padding: 2%;
      
      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5%;

        &__sort {
          white-space: nowrap;
          margin-right: 10px;
        }

        &__dropdown {
          width: 30%;
          margin-right: 10px;
        }
      }

      &__grid {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 95%;
        max-height: 65vh;
        margin: 2% auto;

        &__more {
          width: 100px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          border: none;
          background-color: $transparent;
          &:focus {
            outline: none;
          }
          &__text {
            font-size: 16px;
            font-weight: 700;
            color: black;
          }
        }
      }
  
    }
  
    &__right {
      width: 30%;
      height: 100%;
      margin-top: 2%;
      margin-right: 2%;
      &__top {
        background-color: $white;
        height: 30%;
        width: 100%;
        padding: 2%;
        border: 1px solid rgba(68, 66, 67, 0.2);
        border-radius: 5px;
        margin-bottom: 35px;
        &__fleet {
          display: flex;
          flex-direction: row;
          justify-content: center;
          &__title {
            font-weight: 600;
            width: 50%;
          }
          &__number {
            margin-right: 5px;
          }
          &__text {
            width: 30%;
          }
        }

        &__selec-user {
          margin: 10px 0;
          text-align: center;
          display: flex;
          justify-content: center;
        }

        &__button {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      &__middle {
        height: 300px;
        width: 100%;
        padding: 5%;
        border-radius: 5px;
        background-color: $blue-notification;
        &__title {
          font-weight: 700;
          color: $blue;
          margin-bottom: 20px;
          text-align: center;
          font-size: 20px;
        }
        &__form {
          &__id-container {
            display: flex;
            flex-direction: row;
            margin-bottom: 25px;
            &__id-prefix {
              font-weight: 700;
              color: $blue;
              margin-bottom: 0;
              margin-right: 15px;
              width: 20%;
              padding-left: 15px;
            }
            &__id {
              font-weight: 600;
              color: $blue;
              margin-bottom: 0;
            }
          }
        }
        &__button {
          width: 50%;
          margin: 25px auto;
        }
      }
    }
  }
}