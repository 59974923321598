@import '../../../styles/colors.scss';

.new-delivery-third {
  height: 100%;
  background-color: $white-nav;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__left {
    padding: 1% 1%;
    width: 49%;
    height: 100%;
    background-color: $white;
    border: 1px solid rgba(68, 66, 67, 0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &__container-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      &__num {
        margin-bottom: 0;
        font-size: 12px;
      }
    }
    &__container-BDC {
      display: flex;
      flex-direction: row;
      width: 100%;
      &__num {
        margin-bottom: 0;
        font-size: 12px;
        width: 5%;
      }
      &__prefix {
        font-weight: 600;
        width: 45%;
      }
      &__text {
        color: $blue;
        text-decoration: underline;
        font-weight: bold;
        font-size: 16px;
        width: 40%;
        &__no-import {
          color: $red-bdn;
          font-weight: bold;
        }
      }
      &__icon {
        width: 10%;
        &__img {
          width: 16px;
        }
      }
    }
  }

  &__right {
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__top {
      background-color: $white;
      height: 49%;
      border: 1px solid rgba(68, 66, 67, 0.2);
      border-radius: 5px;
      padding: 3% 3% 0;

      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__button {
          border: none;
          background-color: $transparent;

          &:focus {
            outline: none;
          }
          &__text {
            font-style: italic;
          }
        }
      }

      &__scroll {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 80%;
        border: 1px solid rgba(68, 66, 67, 0.2);
        border-radius: 5px;
        &__line {
          &:nth-child(2n) {
            background-color: $white-nav;
            border: 1px solid rgba(68, 66, 67, 0.2);
          }
        }
      }
    }

    &__middle {
      display: flex;
      background-color: $white;
      height: 35%;
      border: 1px solid rgba(68, 66, 67, 0.2);
      border-radius: 5px;

      &__sign {
        padding-left: 10px;
        padding-top: 10px;
        position: absolute;
      }

      img {
        margin: auto;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &__bottom {
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__button {
        &__back {
          width: 25%;
        }
        &__register {
          width: 65%;
        }
      }
    }
  }
}
