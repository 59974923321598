@import '../../../styles/colors.scss';

@media print {
  .print-delivery {
    -webkit-print-color-adjust: exact;
  }
}

.print-delivery {
  padding: 20px 50px;
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;

    &_informations {
      text-align: right;
    }
  }

  &_informations {
    &_block {
      margin-bottom: 15px;

      p {
        margin-bottom: 0;
      }
    }
  }

  &_materials {
    margin-bottom: 150px;
    &_header {
      display: flex;
      flex-direction: row;
    }
    &_ref {
      width: 80%;
      height: 25px;
      background: rgba(196, 196, 196, 0.2);
      text-align: center;
      margin-right: 1%;
    }

    &_quantity {
      background-color: rgba(196, 196, 196, 0.2);
      width: 29%;
      height: 25px;
      text-align: center;
    }
  }

  &_legalMention {
    &_block {
      margin-bottom: 15px;

      p {
        margin-bottom: 0;
      }
    }
  }

  &_signature {
    margin-left: 50%;

    &_img {
      width: 400px;
    }
  }
}
