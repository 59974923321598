.search-bar {
    position: relative;
    &__mini {
        width: 30px;
    }
    &__icon {
        width: 15px;
        height: 15px;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        &__img {
            width: 100%;
            height: 100%;
            vertical-align: baseline;
        }
    }
}
