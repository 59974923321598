@import '../../styles/colors.scss';

.header {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(68, 66, 67, 0.2);
  background-color: $white;

  &__image {
    width: 220px;
    border: none;
    background-color: $transparent;

    &:focus {
      outline: none;
    }
    &__logo-bdn {
      width: 100px;
    }
  }

  &__title {
    width: calc(100% - 330px);
    padding-left: 30px;
  }

  &__container-date {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 100px;
  }
}
