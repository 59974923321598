@import '../../styles/colors.scss';

.weighing {
  &__container {
    background-color: $white-nav;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__dropdown {
      position: absolute;
      top: 25px;
      left: 50px;
    }

    &__overlay {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    &__form {
      padding: 5%;
      background-color: $white;
      width: 70%;
      border: 1px solid rgba(68, 66, 67, 0.2);
      box-sizing: border-box;
      border-radius: 5px;

      &__item {
        margin-bottom: 25px;
        position: relative;
        left: -15%;
      }
    }
    &__buttons {
      display: flex;
      position: relative;
      top: 25px;
      left: calc(35% - 120px);
      flex-direction: row;
      &__send {
        width: 240px;
        height: 55px;
        margin-left: 10px;
      }
    }
  }
}
