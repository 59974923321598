@import '../../../styles/colors.scss';

.new-delivery-second {
  padding: 2%;
  &__excavation {
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: $blue-light;
    border-radius: 5px;
    height: 55px;
    width: 90%;

    &__information {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      &__text {
        margin-bottom: 0;
        font-weight: 600;
      }

      &__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__button {
          &__icon {
            border: none;
            background-color: $transparent;
            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  &__separator {
    height: 2px;
    width: 40%;
    background-color: rgba(68, 66, 67, 0.2);
    margin-bottom: 2%;
  }

  &__materials {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 35vh;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
  }
}
