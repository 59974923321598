@import '../../styles/colors.scss';

.users {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
    &__left {
      width: 63%;
      height: 100%;
      margin-top: 2%;
      margin-left: 2%;
      background-color: $white;
      border: 1px solid rgba(68, 66, 67, 0.2);
      box-sizing: border-box;
      border-radius: 10px 10px 0px 0px;
      padding: 2%;

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5%;

        &__sort {
          white-space: nowrap;
          margin-right: 10px;
        }

        &__dropdown {
          width: 25%;
          margin-right: 10px;
        }
      }

      &__grid {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 95%;
        height: 65vh;
        margin: 2% auto;
      }

      &__more {
        width: 100px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        border: none;
        background-color: $transparent;
        &:focus {
          outline: none;
        }
        &__text {
          font-size: 16px;
          font-weight: 700;
          color: black;
        }
      }

      &__add {
        background-color: $transparent;
        border: none;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        &:focus {
          outline: none;
        }
        &__text {
          font-weight: 700;
          color: $blue;
          text-decoration: underline;
        }
      }
    }

    &__right {
      width: 30%;
      height: 650px;
      margin-top: 2%;
      margin-right: 2%;
      padding: 1%;
      border-radius: 5px;
      background-color: $blue-notification;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &__title {
        &__text {
          color: $blue;
          font-weight: 700;
        }
      }
      &__text-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        &__prefix {
          font-size: 14px;
          width: 15%;
          margin-left: 5%;
        }
        &__dropdown {
          width: 100%;
        }
      }

      &__password {
        height: 15%;
        &__text-input {
          position: relative;
          left: -10%;
          height: 50%;
        }
        &__error {
          color: $red-error;
        }
      }
      &__button {
        width: 60%;
      }
    }
  }
}
