@import '../../styles/colors.scss';

.trucks {
  background-color: $white-nav;
  overflow: hidden;
  height: 40%;
  &__container {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 95%;
    max-height: 80vh;
    margin: 2% auto;
    background-color: $white;

    &::-webkit-scrollbar-thumb {
      background: rgba(68, 66, 67, 0.8);
    }

    &__header {
      border-radius: 10px 10px 0px 0px;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 30px;
      &__search-bar {
        width: 350px;
        position: relative;
        top: -6px;
      }

      &__colLabels {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        &__licensePlate {
          width: 17%;
          text-align: center;
          margin-bottom: 0;
          font-weight: 700;
        }

        &__emptyWeight {
          width: 17%;
          text-align: center;
          margin-bottom: 0;
          font-weight: 700;
        }

        &__creationDate {
          width: 17%;
          text-align: center;
          margin-bottom: 0;
          font-weight: 700;
        }

        &__creationTime {
          width: 17%;
          text-align: center;
          margin-bottom: 0;
          font-weight: 700;
        }

        &__weights {
          width: 17%;
          text-align: center;
          margin-bottom: 0;
          font-weight: 700;
        }
      }
    }

    &__body {
      border: 1px solid $white-nav;
    }

    &__no_truck {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__text {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
