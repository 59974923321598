@import '../../../styles/colors.scss';

.truck-line {
  &__weight-modif {
    &__overlay {
      position: fixed;
      left: calc(50% - 200px);
      animation-name: BottomToTop;
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
    }
  }

  &__informations {
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;

    &__selected {
      background-color: rgba(5, 131, 242, 0.2);
    }
    &__divselec {
      width: 5%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &__selec {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $black;
        box-sizing: border-box;

        &__selected {
          background-color: $blue;
          border: none;
        }
      }
    }

    &__immat {
      padding-top: 14px;
      width: 17%;
    }

    &__empty-weight {
      font-style: italic;
      padding-top: 14px;
      width: 17%;
    }

    &__date {
      font-style: italic;
      padding-top: 14px;
      width: 17%;
    }

    &__time {
      font-style: italic;
      padding-top: 14px;
      width: 17%;
    }

    &__weight {
      width: 17%;
      border: none;
      background-color: $transparent;

      &:focus {
        outline: none;
      }

      &__text {
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 0;
      }
      &__overlay {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        position: relative;
        left: 40px;
        top: 10px;
        right: 290px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 250px;
        border: none;
        background-color: $white;

        &__content {
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          &__text {
            font-weight: bold;
            text-align: right;
            margin-right: 5px;
            width: 60%;
          }

          &__value {
            width: 20%;
          }

          &__unit {
            margin-left: 5px;
            width: 5%;
          }
        }
        &__separator {
          height: 1px;
          width: 90%;
          background-color: rgba(68, 66, 67, 0.2);
        }
      }
    }

    &__group-button {
      width: 15%;
      &__button {
        border: none;
        background-color: $transparent;

        &:focus {
          outline: none;
        }

        &__icon {
          width: 20px;
          height: 20px;
          &__img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @keyframes BottomToTop {
    0% {
      top: 150%;
    }
    100% {
      top: calc(50% - 300px);
    }
  }
}
