@import '../../styles/colors.scss';

.settings {
  &__card {
    height: -webkit-fill-available;
    margin: 2%;
    background-color: $white;
    border: 1px solid rgba(68, 66, 67, 0.2);
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    padding: 2%;
    &__section {
      margin-bottom: 50px;
      &__title {
        font-size: 18px;
        font-weight: 600;
      }
      &__description {
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 20px;
      }
      &__error {
        color: $red-error;
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        margin: 0px;
      }
      &__fileName {
        font-size: 12px;
        font-weight: 600;
        font-style: italic;
        margin: 0px;
      }
      &__btn {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
