@import "../../../styles/colors.scss";

.radio-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  &__prefixed {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 30%;
    padding-right: 5%;
    text-transform: capitalize;
  }

  &__choices {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      &__checked {
        background-color: rgba(5, 131, 242, 0.2);
        position: relative;
        left: -21px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
      }

      &__input {
        width: 16px;
        height: 16px;

        &:checked {
          background-color: $blue;
        }
      }
  
      &__text {
          padding-left: 20px;
          margin-bottom: 0;
          font-size: 16px;
          &::first-letter {
            font-weight: bold;
            text-decoration: underline;
          }

          &__checked {
            margin-right: 0;
            font-size: 16px;
            &::first-letter {
              font-weight: bold;
              text-decoration: underline;
            }
          }
      }
    }
  }
}