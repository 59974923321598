@import "../../../styles/colors.scss";

.overlayValidate {
  width: 100;
  height: 100%;
  border-radius: 5px;

  &__background {
    width: 100%;
    height: 100%;
    background-color: $green-valid;
    animation-name: Appear;
    animation-duration: 2s;
    animation-fill-mode:forwards;
    }

  &__layout {
    position: relative;
    top: -60%;
    left: 45%;
    &__container {
      width: 150px;
      height: 150px;
      border-radius: 5px;
      background-color: $white;
      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 3%;
      right: 3%;

      &__icon {
        &__img {
          width: 60px;
          height: 60px;
        }
      }
  
      &__text {
        color: $green-valid;
        font-weight: 800;
      }
    }
  }

}

@keyframes Appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}