@import "../../styles/colors.scss";

.reset-pw {
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  
    &__container {
      background-color: $white;
      height: 100%;
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 2% 5%;
      
      &__image {
        width: 100%;
        // padding-top: 2%;
        // padding-right: 4%;
        &__logo-bdn {
          width: 130px;
          float: right;
           
        }
      }
  
      &__informations {
        padding: 4% 45% 10% 2%;
        height: 60%;
        display: flex;
        flex-direction: column;

        &__title {
          margin-top: 8%;
          margin-bottom: 8%;
        }

        &__text1 {
          margin-bottom: 8%;
        }
  
        &__form {
          display: flex;
          flex-direction: column;
          height: 50px;
  
          &__password {
            margin-bottom: 4%;
          }

          &__password-confirm {
            margin-bottom: 4%;

            &__error {
              margin-top: -3%;
              color: $red-error;
            }
          }

          &__needed {
            margin-bottom: 8%;
            &__text {
              margin-bottom: 4%;
            }
            &__list {

            }
        }

          &__send {
            margin-left: 20%;
          }
        }
      }
    }
  }