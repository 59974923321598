@import "../../../styles/colors.scss";
/*
.Dropdown-root {
  width: 100%;
  background: #FFFFFF;
  border: 2px solid $blue;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__disable {
    background: $white-nav;
    border: 2px solid $gray-bdn;
    color: $gray-bdn;
  }
}
.Dropdown-control {
  border: none !important;
  background-color: $transparent !important;
  border-radius: 0 !important;
  color: $blue !important;
  font-weight: bold !important;
  width: 100%;
  z-index: 2;

  &__disable {
    color: $gray-bdn !important;
  }
}

.Dropdown-menu {
  background: $transparent;
  border: 1px solid rgba(5, 131, 242, 0.1);
  border-radius: 0px 0px 10px 10px;

}

.Dropdown-arrow {
  display: none !important;
}

.drop-down {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__icon {
    width: 12px;
    height: 6px;
    position: relative;
    top: -9px;
    left: -25px;
    &__img {
      height: 100%;
    }
    &__img-reverse {
      height: 100%;
      transform: rotate(180deg);
    }
  }
}*/


.drop-down {
  position: relative;
  width: 100%;
  margin-bottom: 0;
 // min-width: 200px;
}


svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  stroke-width: 2px;
  stroke: $blue;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
}


.select {
  -webkit-appearance: none;
  padding: 0 10px;
  margin-bottom: 0;
  width: 100%;
  height: 40px;
  border: 2px solid $blue;
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 3px -2px #9098A9;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
  color: $blue;
  font-weight: 600;
  &:required:invalid {
    color: #5A667F;
  }
  
  &:disabled {
    border: 2px solid $gray-bdn;
  }

  &__options {
    color: $blue;
    &[value=""][disabled] {
      display: none
    }

    &:focus {
      outline: none;
    }

    &:hover + svg {
      stroke: $blue
    }
  }
}

.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
