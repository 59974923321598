// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import 'breakpoints';
@import 'colors';
@import 'template';

h1,
h2 p {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h2 {
  font-size: 18px;
  font-weight: normal;
}

p {
  font-size: 16px;
}
