@import '../../../styles/colors.scss';

.uiInput {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;

  &_container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &_prefix {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
    text-transform: capitalize;
    padding-right: 15px;
  }

  &_field {
    background-color: $white;
    border: 1.5px solid rgba(68, 66, 67, 0.6);
    box-sizing: border-box;
    box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-size: 24px;
    padding: 1% 1%;
    width: 65%;
    height: 35px;
    display: flex;
    align-items: center;

    &::placeholder {
      color: $place-holder;
      font-weight: normal;
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border: 2px solid $blue;
      box-sizing: border-box;
      box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.25);
      color: $blue;
    }

    &--error {
      border: 2px solid $red-error;
      box-sizing: border-box;
      box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.25);
      color: $red-error;
    }

    &--success {
      border: 2px solid $green-valid;
    }

    &--shiny {
      border: 2px solid #0583f2;
      box-shadow: 0px 0px 4px #0583f2, inset 0px 3px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &_containerUnit {
    width: 0;
    position: relative;
    right: -10px;
    display: flex;
    align-items: center;
  }

  &_unit {
    font-weight: bold;
  }
}
