.auto-complete {
    height: 170px;
    overflow: scroll;
    position: absolute;
    background-color: white;
    width: 30%;
    filter: drop-shadow(0px 2px 7px rgba(68, 66, 67, 0.1));
    padding: 10px 0;
    border-radius: 0 0 4px 4px;
    z-index: 2;
    button {
        border: none;
        display: block;
        background-color: transparent;
        font-family: Lato;
        padding: 5px 10px;
        width: 100%;
        text-align: left;
    }
}

.clients--inactive {
    color: red;
}