@import '../../../styles/colors.scss';

.new-delivery-first {
  height: 100%;
  &__container {
    height: 100%;
    &__form {
      padding: 2%;
      height: 75%;

      &__top {
        display: flex;
        flex-direction: row;
        height: 60%;

        &__left {
          width: 50%;
        }

        &__right {
          width: 50%;
        }
      }
      &__bottom {
        height: 40%;
      }

      &__container-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 5px;
        height: 50px;

        &__num {
          margin-bottom: 0;
          font-size: 12px;
        }

        &__scan {
          margin-top: 5%;
          margin-bottom: 5%;
          &__button {
            width: 143px;
            height: 43px;
            background-color: $white;
            padding: 3% 10%;
            border: 2px solid #0583f2;
            box-sizing: border-box;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            &__text {
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              color: $blue;
              font-weight: bold;
              font-size: 16px;
            }
          }

          &__text {
            margin-bottom: 0;
            font-weight: 600;
            width: 39%;
            text-align: right;
            padding-right: 5%;
          }
          &__name-file {
            margin-left: 10px;
            font-size: 12px;
          }
        }

        &__evac-text {
          margin-bottom: 0;
          font-weight: 600;
          margin-left: 20px;

          &::first-letter {
            font-weight: 700;
            text-decoration: underline;
          }
        }

        &__check {
          padding-left: 13%;
        }

        &__checkbox {
          width: 22px;
          height: 22px;
          border: 1px solid rgba(68, 66, 67, 0.2);
          border-radius: 5px;
          box-sizing: border-box;
          margin: 0;

          &:checked {
            background-color: $blue;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
          }
        }
      }
    }

    &__submit {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 50px;
      margin-top: 5%;

      &__button {
        width: 240px;
        height: 50px;
      }
    }
  }
}

// ========== Check box stuff ==========

.checkbox {
  position: relative;
  input,
  svg {
    width: 22px;
    height: 22px;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: white;
    border: 2px solid $blue;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;

    &:checked {
      --b: #1e2235;
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, #1e2235);
    position: absolute;
    top: 0;
    left: 0;
    width: 21px;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }
  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;
        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }
    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }
  &.bounce {
    --stroke: white;
    margin-bottom: 0;
    input {
      &:checked {
        --s: 11px;
        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }
    svg {
      --scale: 0;
    }
  }

  .input__station {
    width: 22px;
    height: 22px;
    border: 3px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0;

    &:checked {
      background-color: #5fc09f;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
