@import "../../../styles/colors.scss";

.uiButton {
  border-radius: 5px;
  padding: 12px 16px;

  &:disabled {
    background-color: rgba(68, 66, 67, 0.6);
  }

  &--primary {
    background-color: $blue;
    border: none;
  }

  &--secondary {
    background-color: $white;
    border: 2px solid #0583F2;
  }

  &_label {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    &--primary {
      color: $white;
      text-transform: uppercase;
    }
    &--secondary {
      color: $blue;
    }
  }
}