@import '../../../styles/colors.scss';

.truck-modif {
  width: 600px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgba(68, 66, 67, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  background-color: $white;

  &__title {
    padding-left: 30px;
  }

  &__form {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;

    &__line {
      display: flex;
      flex-direction: row;
      width: 90%;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
}
