@import "../../styles/colors.scss";

.forgot-pw {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &__container {
    background-color: $white;
    height: 100%;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
    
    &__image {
      width: 100%;
      // padding-top: 2%;
      // padding-right: 4%;
      &__logo-bdn {
        width: 130px;
        float: right;
         
      }
    }

    &__informations {
      padding: 2% 5%;
      height: 60%;
      display: flex;
      flex-direction: column;

      &__title {
        margin-top: 4%;
        margin-bottom: 8%;
      }

      &__text1 {
        margin-bottom: 4%;
      }

      &__text2 {
        margin-bottom: 4%;
      }

      &__form {
        display: flex;
        flex-direction: row;
        height: 50px;

        &__email {
          width: 55%;
          position: relative;
          left: 4%;
        }
        &__send {
          width: 25%;
        }
      }

      &__email-send {
        margin-left: 4%;
        color: $blue;
      }
    }
  }
}