@import "../../../styles/colors.scss";

.comeBackButton {
    background-color: $transparent;
    padding: 3% 4% 3% 0%;
    display: flex;
    flex-direction: row;
    width: 100px;
    border: none;

    &:focus {
        outline: none;
    }

    &__arrow {
        height: 14px;
        width: 7px;
        margin-right: 10px;
    }

    &__text {
        text-transform: capitalize;
        color: $gray-bdn;
        position: relative;
        top: -10%;
    }


}