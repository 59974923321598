@import '../../../styles/colors.scss';

.delivery-line {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  gap: 20px;

  &__arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 30px;
    left: 90%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    &__hidden {
      visibility: hidden;
    }

    &__icon {
      &__img {
        width: 16px;
      }
    }
  }

  &__selected {
    background-color: $blue-selec;
  }
  &__selec {
    &__circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $black;
      box-sizing: border-box;

      &__selected {
        background-color: $blue;
        border: none;
      }
    }
    &__icon {
      width: 50px;
      position: relative;
      top: -20px;
      &__img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__first {
    width: 25%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    &__mini {
      width: 35%;
    }
    &__dateNtime {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      &__date {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        color: $blue;
        margin: 0px;
      }
      &__time {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        color: $blue;
      }
    }

    &__client {
      font-weight: 600;
      font-size: 16px;
      margin: 0px;
      &--valid {
        font-weight: 600;
        font-size: 16px;
        color: $green-valid;
        margin: 0px;
      }
    }

    &__code {
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      margin: 0px;
    }
  }

  &__second {
    text-align: left;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    &__mini {
      width: 40%;
    }

    &__adress {
      font-size: 14px;
      margin: 0px;
    }

    &__city {
      font-size: 14px;
      margin: 0px;
    }

    &__immatriculation {
      font-size: 16px;
      margin: 0px;
    }
  }

  &__third {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    &__material {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
      &__name {
        font-weight: 700;
        width: 75%;
        margin: 0px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        margin-right: 5px;
      }
      &__weight {
        margin-bottom: 0;
        font-size: 16px;
      }
      &__unit {
        font-weight: 600;
        margin-bottom: 0;
        text-align: center;
        font-size: 16px;
      }
    }
  }

  &__fourth {
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__mini {
      margin-top: 0;
    }

    &__status {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      &__large {
        width: 100%;
      }
    }

    &__button {
      border: none;
      background-color: $transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 30px;
      width: 100%;
      margin-bottom: 5px;

      &__mini {
        width: 32px;
      }
      &__icon {
        margin-right: 10px;
        width: 20px;

        &__mini {
          margin-right: 0;
        }
        &__img {
          width: 100%;
          height: 100%;
        }
      }

      &:focus {
        outline: none;
      }

      &__text {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
  }
}

.status-overlay {
  width: 0;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: $white;
  position: relative;
  top: 15px;
  left: -14%;
  z-index: 3;
  &__button {
    height: 30px;
    width: 120px;
    border: none;
    background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__icon {
      margin-right: 10px;
      width: 15px;
      height: 15px;
      &__img {
        width: 100%;
        height: 100%;
      }
    }

    &__text {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
