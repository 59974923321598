$green-valid: #c4d926;
$blue: #0583f2;
$blue-hover: #6bbaff;
$blue-light: #ddecf9;
$blue-notification: #cde6fc;
$blue-selec: #ebf5fe;
$red-error: #f24405;
$red-bdn: #e0081d;
$white-back: #f6f6f6;
$white-nav: #f4f4f4;
$gray-bdn: #444243;
$white: #fff;
$black: #000;
$place-holder: #cdcdcd;
$transparent: #00000000;
